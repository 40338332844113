'use strict'

const React = require('react')
const styles = require('./anchored-popover.css')
const {Box, Popover: PrimerPopover} = require('@primer/react')

// Custom popover component that is spiritually similar to Primer's AnchoredOverlay component.
// Once wubwub is running on React 17+ we can pull in the latest version of @primer/react and
// replace this component with the AnchoredOverlay component.
class AnchoredPopover extends React.PureComponent {
  constructor(props) {
    super(props)

    this.popover = React.createRef()
    this.anchor = React.createRef()

    this.keydownHandler = this.keydownHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.handleFocusOutside = this.handleFocusOutside.bind(this)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  open() {
    this.setState({isOpen: true})
  }

  close() {
    this.setState({isOpen: false})
  }

  clickHandler(ev) {
    if (ev.defaultPrevented || ev.button !== 0) {
      return
    }

    if (!this.state.isOpen) {
      this.open()
    } else {
      this.close()
    }
  }

  keydownHandler(ev) {
    if (!ev.defaultPrevented) {
      if (ev.key === 'Escape') {
        if (this.anchor.current) {
          this.anchor.current.focus()
        }
        this.close()
      } else if (!this.state.isOpen && ['ArrowDown', 'ArrowUp', ' ', 'Enter'].includes(ev.key)) {
        this.open()
        ev.preventDefault()
      }
    }
  }

  handleFocusOutside(event) {
    const popoverContentReceivedFocus = this.popover.current && this.popover.current.contains(event.target)
    const popoverAnchorReceivedFocus = this.anchor.current && this.anchor.current.contains(event.target)
    // Close if focusing anywhere outside the popover or anchor button
    if (!popoverContentReceivedFocus && !popoverAnchorReceivedFocus) {
      this.close()
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.isOpen && !prevState.isOpen && this.popover.current) {
      this.popover.current.setAttribute('tabindex', '-1')
      this.popover.current.focus({preventScroll: true})
    }
  }

  componentDidMount() {
    if (this.popover.current) {
      this.popover.current.addEventListener('keydown', this.keydownHandler)
    }
    document.addEventListener('mousedown', this.handleFocusOutside)
    document.addEventListener('focusin', this.handleFocusOutside)
    document.addEventListener('blur', this.handleFocusOutside)
  }

  componentWillUnmount() {
    if (this.popover.current) {
      this.popover.current.removeEventListener('keydown', this.keydownHandler)
    }
    document.removeEventListener('mousedown', this.handleFocusOutside)
    document.removeEventListener('focusin', this.handleFocusOutside)
    document.removeEventListener('blur', this.handleFocusOutside)
  }

  render() {
    const {isOpen} = this.state
    const {renderAnchor, children} = this.props
    return (
      <Box className={styles.container}>
        {renderAnchor &&
          renderAnchor({
            ref: this.anchor,
            'aria-haspopup': 'true',
            'aria-expanded': isOpen ? 'true' : undefined,
            onClick: this.clickHandler,
            onKeyDown: this.keydownHandler,
          })}

        <Box className={styles.popover} ref={this.popover}>
          <PrimerPopover relative open={isOpen}>
            {isOpen ? <Box className={styles.popoverMessage}>{children}</Box> : null}
          </PrimerPopover>
        </Box>
      </Box>
    )
  }
}

module.exports = AnchoredPopover

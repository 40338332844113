'use strict'

const React = require('react')
const styles = require('./provenance.css')
const iconStyles = require('../icons/icons.css')
const {VerifiedIcon, StopIcon, AlertIcon} = require('@primer/octicons-react')
const {Box} = require('@primer/react')
const AnchoredPopover = require('../anchored-popover/anchored-popover')
const Spinner = require('../spinner/spinner')
const Link = require('@npm/spiferack/link')
const connect = require('../connect')

function sourceCommitUnreachableMessage(sourceCommitResponseCode, short = false) {
  if (sourceCommitResponseCode === 404) {
    return short
      ? `Unable to find the source commit for this package.`
      : `Unable to find the source commit for this package. This may be due to a deleted or inaccessible repository. Please verify the source before using this package.`
  } else if (sourceCommitResponseCode === 408) {
    // Set by tma-client.js when the tma api request times out after 2s
    return short
      ? 'Unable to fetch the source commit for this package.'
      : 'It is taking too long to fetch the source commit for this package. Please try refreshing or verifying the source before using this package.'
  } else {
    const prefix = 'Unable to fetch the source commit for this package.'
    const detail = short
      ? ``
      : ` The source host responded with an error: ${sourceCommitResponseCode}. Please try refreshing or verifying the source before using this package.`
    return `${prefix}${detail}`
  }
}

function ProvenanceDetailsContainer({
  summary,
  sourceCommitResponseCode,
  sourceCommitUnreachable,
  sourceCommitNotFound,
}) {
  const {sourceRepositoryDigest, sourceRepositoryUri} = summary
  const shortDigest = sourceRepositoryDigest ? sourceRepositoryDigest.slice(0, 7) : sourceRepositoryDigest
  let repoHostWithProject = sourceRepositoryUri
  try {
    const repoUrl = new URL(sourceRepositoryUri)
    repoHostWithProject = repoUrl.host + repoUrl.pathname
  } catch (_) {}

  return (
    <Box>
      <Box className={styles.content}>
        <Box flexGrow={1}>
          <Box className={`${styles.runnerPlatformContainer} ${sourceCommitUnreachable ? styles.withoutIcon : ''}`}>
            {!sourceCommitUnreachable && (
              <Box className={styles.verifiedIconMobile}>
                <VerifiedIcon className={iconStyles.checkMarkIcon} size={16} />
              </Box>
            )}
            {!sourceCommitUnreachable && (
              <Box className={styles.verifiedIconDesktop}>
                <VerifiedIcon className={iconStyles.checkMarkIcon} size={24} />
              </Box>
            )}
            <span className={styles.runnerPlatformLabel}>Built and signed on</span>
            <h2 className={styles.runnerPlatformName}>{summary.issuerDisplayName}</h2>
            <a
              className={styles.runnerSummaryLink}
              href={summary.runInvocationUri}
              target="_blank"
              rel="noopener noreferrer"
            >
              View build summary
            </a>
          </Box>
        </Box>
        <Box className={styles.detailsContainer}>
          <p className={styles.detailsLabel} id="provenance-details-source-commit">
            Source Commit
          </p>
          <a
            className={styles.detailsLink}
            href={summary.resolvedSourceRepositoryCommitUri}
            target="_blank"
            rel="noopener noreferrer"
            aria-labelledby="provenance-details-source-commit"
          >
            {[repoHostWithProject, shortDigest].filter(Boolean).join('@')}
          </a>
          <p className={styles.detailsLabel} id="provenance-details-build-file">
            Build File
          </p>
          <a
            className={styles.detailsLink}
            href={summary.resolvedBuildConfigUri}
            target="_blank"
            rel="noopener noreferrer"
            aria-labelledby="provenance-details-build-file"
          >
            {summary.buildConfigDisplayName}
          </a>
          <p className={styles.detailsLabel}>Public Ledger</p>
          <a className={styles.detailsLink} href={summary.transparencyLogUri} target="_blank" rel="noopener noreferrer">
            Transparency log entry
          </a>
        </Box>
      </Box>
      {sourceCommitUnreachable && (
        <Box>
          <Box className={styles.popoverContentDivider} />
          <Box className={styles.sourceCommitUnreachableContainer}>
            <Box className={styles.sourceCommitUnreachableIcon}>
              {sourceCommitNotFound ? (
                <StopIcon aria-label="Alert" className={iconStyles.stopIcon} size={16} />
              ) : (
                <AlertIcon aria-label="Warning" className={iconStyles.warningIcon} size={16} />
              )}
            </Box>
            <span className={styles.sourceCommitUnreachableLabel} data-testid="source-commit-error">
              {sourceCommitUnreachableMessage(sourceCommitResponseCode)}
            </span>
          </Box>
        </Box>
      )}
    </Box>
  )
}

function ProvenanceLoading() {
  return (
    <Box className={styles.blankslate}>
      <Spinner color="black" size={32} aria-label={'Loading provenance'} />
    </Box>
  )
}

function ProvenanceError({dispatch, error}) {
  const {name, version} = error
  const onClickHandler = () => {
    dispatch('FETCH_PROVENANCE_DETAILS', {name, version})
  }

  return (
    <Box className={styles.blankslate}>
      <h1 className={styles.blankslateHeader}>Failed to load provenance</h1>
      <div className={styles.blankslateAction}>
        <button className={styles.blankslateButton} onClick={onClickHandler}>
          Try Again
        </button>
      </div>
    </Box>
  )
}

const ConnectedProvenanceError = connect()(ProvenanceError)

class ProvenanceDetails extends React.PureComponent {
  constructor(props) {
    super(props)

    this.container = React.createRef()
    this.onFocus = this.onFocus.bind(this)
    this.state = {isFocused: false}
  }

  onFocus(event) {
    if (event.target === this.container.current) {
      this.setState({isFocused: true})
    } else {
      this.setState({isFocused: false})
    }
  }

  componentDidMount() {
    document.addEventListener('focusin', this.onFocus, true)
  }

  componentWillUnmount() {
    document.removeEventListener('focusin', this.onFocus, true)
  }

  render() {
    const {isFocused} = this.state
    const {provenance} = this.props
    // Note: <Scroll /> to content behaviour on the package page requires the target
    // element to have an id matching `#user-content-${window.location.hash}
    return (
      <aside
        className={styles.container}
        ref={this.container}
        id="user-content-provenance"
        aria-describedby="provenance-details-header"
      >
        <h1 id="provenance-details-header" className={styles.header}>
          Provenance
        </h1>
        <Box
          className={`${styles.borderBox} ${
            provenance.details && provenance.details.sourceCommitUnreachable
              ? provenance.details.sourceCommitNotFound
                ? styles.borderBoxSourceCommitNotFound
                : styles.borderBoxSourceCommitUnreachable
              : ''
          } ${
            isFocused
              ? provenance.details && provenance.details.sourceCommitUnreachable
                ? provenance.details.sourceCommitNotFound
                  ? styles.isFocusedWithSourceCommitNotFound
                  : styles.isFocusedWithSourceCommitUnreachable
                : styles.isFocused
              : ''
          }`}
        >
          {provenance.loading || (!provenance.error && !provenance.details) ? (
            <ProvenanceLoading />
          ) : provenance.error ? (
            <ConnectedProvenanceError error={provenance.error} />
          ) : (
            provenance.details && (
              <ProvenanceDetailsContainer
                summary={provenance.details.summary}
                sourceCommitResponseCode={provenance.details.sourceCommitResponseCode}
                sourceCommitUnreachable={provenance.details.sourceCommitUnreachable}
                sourceCommitNotFound={provenance.details.sourceCommitNotFound}
              />
            )
          )}
        </Box>

        {provenance.feedbackUrl && (
          <a className={styles.feedbackLink} href={provenance.feedbackUrl} target="_blank" rel="noopener noreferrer">
            <span className="underline">Share feedback</span>
          </a>
        )}
      </aside>
    )
  }
}

function ProvenancePopoverLoading() {
  return (
    <Box className={styles.popoverWide}>
      <Box className={styles.popoverContent}>
        <Box className={styles.popoverBlankslate}>
          <Spinner color="black" size="24" className={styles.popoverBlankslateIcon} aria-label={'Loading provenance'} />
        </Box>
      </Box>
    </Box>
  )
}

function ProvenancePopoverError({dispatch, error}) {
  const {name, version} = error
  const onClickHandler = () => {
    dispatch('FETCH_PROVENANCE_DETAILS', {name, version})
  }

  return (
    <Box className={styles.popoverWide}>
      <Box className={styles.popoverContent}>
        <h1 className={styles.blankslateHeader}>Failed to load provenance</h1>
        <div className={styles.popoverBlankslateAction}>
          <button className={styles.popoverBlankslateButton} onClick={onClickHandler}>
            Try Again
          </button>
        </div>
      </Box>
    </Box>
  )
}

const ConnectedProvenancePopoverError = connect()(ProvenancePopoverError)

function ProvenancePopoverDetails({
  provenanceDetailsUrl,
  runnerPlatformName,
  sourceCommitResponseCode,
  sourceCommitUnreachable,
  sourceCommitNotFound,
}) {
  return (
    <Box className={styles.popoverWide}>
      <Box className={styles.popoverContent}>
        <Box
          className={`${styles.runnerPlatformContainerPopover} ${
            sourceCommitUnreachable ? styles.withoutIconPopover : ''
          }`}
        >
          {!sourceCommitUnreachable && (
            <Box className={styles.verifiedIconPopover}>
              <VerifiedIcon className={iconStyles.checkMarkIcon} size={24} />
            </Box>
          )}
          <span className={styles.runnerPlatformLabel}>Built and signed on</span>
          <h2 className={styles.runnerPlatformNamePopover}>{runnerPlatformName}</h2>
        </Box>
      </Box>
      {sourceCommitUnreachable && (
        <Box className="ph3 pb3">
          <Box className={styles.sourceCommitUnreachableContainerPopover}>
            <Box className={styles.sourceCommitUnreachableIcon}>
              {sourceCommitNotFound ? (
                <StopIcon aria-label="Alert" className={iconStyles.stopIcon} size={16} />
              ) : (
                <AlertIcon aria-label="Warning" className={iconStyles.warningIcon} size={16} />
              )}
            </Box>
            <span className={styles.sourceCommitUnreachableLabel} data-testid="source-commit-error">
              {sourceCommitUnreachableMessage(sourceCommitResponseCode, true)}
            </span>
          </Box>
        </Box>
      )}
      <Box className={styles.popoverContentDivider} />
      <Box className={styles.popoverContent}>
        <Link replace>
          <a href={provenanceDetailsUrl} className={styles.runnerSummaryLink}>
            View more details
          </a>
        </Link>
      </Box>
    </Box>
  )
}

function ProvenancePopover({provenance, provenanceDetailsUrl, children}) {
  return (
    <AnchoredPopover
      renderAnchor={anchorProps => (
        <button {...anchorProps} className={styles.popoverButton} aria-label="View more provenance details">
          {children}
        </button>
      )}
    >
      {provenance.loading || (!provenance.error && !provenance.details) ? (
        <ProvenancePopoverLoading />
      ) : provenance.error ? (
        <ConnectedProvenancePopoverError error={provenance.error} />
      ) : (
        provenance.details && (
          <ProvenancePopoverDetails
            provenanceDetailsUrl={provenanceDetailsUrl}
            runnerPlatformName={provenance.details.summary.issuerDisplayName}
            sourceCommitResponseCode={provenance.details.sourceCommitResponseCode}
            sourceCommitUnreachable={provenance.details.sourceCommitUnreachable}
            sourceCommitNotFound={provenance.details.sourceCommitNotFound}
          />
        )
      )}
    </AnchoredPopover>
  )
}

function StaticProvenancePopover({provenanceDetailsUrl, children}) {
  return (
    <AnchoredPopover
      renderAnchor={anchorProps => (
        <button {...anchorProps} className={styles.popoverButton} aria-label="View more provenance details">
          {children}
        </button>
      )}
    >
      <Box className={styles.popoverNarrow}>
        <Box className={styles.popoverContent}>
          <Box className={styles.runnerPlatformContainerStaticPopover}>
            <Box className={styles.verifiedIconPopover}>
              <VerifiedIcon className={iconStyles.checkMarkIcon} size={24} />
            </Box>
            <span className={styles.runnerPlatformLabelStatic}>Built and signed {`\nwith provenance`}</span>
          </Box>
        </Box>
        <Box className={styles.popoverContentDivider} />
        <Box className={styles.popoverContent}>
          <Link replace>
            <a href={provenanceDetailsUrl} className={styles.runnerSummaryLink}>
              View more details
            </a>
          </Link>
        </Box>
      </Box>
    </AnchoredPopover>
  )
}

module.exports = {
  ProvenancePopover,
  ProvenanceDetails,
  StaticProvenancePopover,
}
